/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import api, { url } from "../../api";
import UploadForm from "../predios/novoPredio/components/uploadForm";
import { Input, Form, Button, message, Select } from "antd";
import { navigate } from "gatsby";
import { AuthContext } from "../../context/GlobalContextProvider";
import { LoadingOutlined } from "@ant-design/icons";
import PrediosSelect from "./components/prediosCarrossel";

export default function Sobre() {
  const [page, setPage] = useState();
  const context = useContext(AuthContext);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  useEffect(() => {
    getPage();
  }, []);

  const getPage = async () => {
    setLoadingData(true);
    const { data } = await api.get("/sobre");
    setPage(data);
    setLoadingData(false);
  };

  const handleSubmit = async values => {
    setLoadingSave(true);
    var data = new FormData();
    values.imagem &&
      data.append(
        `files.imagem`,
        values.imagem[0]?.originFileObj,
        values.imagem[0]?.name
      );

    data.append(
      "data",
      JSON.stringify({
        quemSomos: values.quemSomos,
        missao: values.missao,
        valores: values.valores,
        visao: values.visao,
        compromissoSocial: values.compromissoSocial,
        predios: values.carousel
      })
    );
    localStorage.removeItem("sobre");
    await api({
      method: "PUT",
      url: "/sobre",
      headers: {
        Authorization: `Bearer ${context?.token}`
      },
      data
    }).then(value => {
      if (value) {
        message.success("Página salva com sucesso");
        navigate(`/sobre`);
      }
    });
    setLoadingSave(false);
  };

  return (
    <Layout image="" loading={loadingData}>
      <SEO title="Quem somos" />
      <Form layout="vertical" onFinish={handleSubmit}>
        <PrediosSelect
          initialValue={page?.predios}
          carrossel
          {...{ Form, name: "carousel" }}
        />
        <h2 id="quemSomos" className="mt-5">
          Quem Somos?
        </h2>
        <Form.Item name="quemSomos" initialValue={page?.quemSomos}>
          <Input.TextArea className="mb-10" autoSize />
        </Form.Item>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 lg:gap-8 my-12">
          <UploadForm imgUrl={`${url}${page?.imagem?.url}`} field="imagem" Form={Form} />
        </div>
        <h2 id="missao" className="mb-3 mt-10">
          Missão
        </h2>
        <Form.Item name="missao" initialValue={page?.missao}>
          <Input.TextArea autoSize />
        </Form.Item>
        <h2 id="visao" className="mb-3 mt-10">
          Visão
        </h2>
        <Form.Item name="visao" initialValue={page?.visao}>
          <Input.TextArea autoSize />
        </Form.Item>
        <h2 id="valores" className="mb-3 mt-10">
          Valores
        </h2>
        <Form.Item name="valores" initialValue={page?.valores}>
          <Input.TextArea autoSize />
        </Form.Item>
        <h2 id="compromissoSocial" className="mb-3 mt-10">
          Compromisso social
        </h2>
        <Form.Item
          name="compromissoSocial"
          initialValue={page?.compromissoSocial}
        >
          <Input.TextArea autoSize />
        </Form.Item>
        <Form.Item>
          <Button
            style={{ backgroundColor: "orange", color: "white" }}
            htmlType="submit"
          >
            {loadingSave && <LoadingOutlined />} Salvar
          </Button>
        </Form.Item>
      </Form>
    </Layout>
  );
}
